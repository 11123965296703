import { memo, useCallback, useState } from 'react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'

import useSearch from '@sholdi/hooks/useSearch'
import useTranslation from '@sholdi/hooks/useTranslation'

import { keyCodes } from '@sholdi/shared/lib/constants/keyCodes'

import SearchIcon from '@sholdi/icons/Search'
import Text from '@sholdi/primitives/atoms/Text'
import SearchField from '../../../molecules/Search'
import ClickOutside from '../../../lib/ClickOutside'
import getEnv from '@sholdi/shared/lib/env/getEnv'

const RecentSearches = dynamic(() => import('./RecentSearches'), {
  ssr: false,
})
const SuggestResults = dynamic(() => import('./SuggestResults'), {
  ssr: false,
})
const SearchResults = dynamic(() => import('./SearchResults'), { ssr: false })
const ProductsResults = dynamic(() => import('./ProductsResults'), {
  ssr: false,
})
const Shops = dynamic(() => import('./Shops'), { ssr: false })
const Spinner = dynamic(() => import('@sholdi/primitives/atoms/Spinner'), {
  ssr: false,
})

const Search = ({ className, ...props }) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const {
    onChange,
    isLoading,
    searchTerm,
    searchResults,
    searchSuggestions,
    suggestedProducts,
    featuredProductsLoading,
    setSearchTerm,
  } = useSearch()

  const onKeyDown = useCallback(
    e => {
      const query = e.target.value
      if (query && query.length > 2) {
        if (e.keyCode === keyCodes.ENTER) {
          e.preventDefault()
          const items =
            localStorage.getItem('searches') &&
            Array.isArray(JSON.parse(localStorage.getItem('searches')))
              ? JSON.parse(localStorage.getItem('searches'))
              : []
          if (!items.includes(query)) {
            localStorage.setItem(
              'searches',
              // leave new item and last four items from items array
              JSON.stringify([query, ...items.slice(0, 4)]),
            )
          }
          router
            .push({
              pathname: '/search',
              query: {
                q: query,
              },
            })
            .then(() => {
              setIsMenuVisible(false)
              e.target.blur()
            })
        }
      }
    },
    [router],
  )
  const hideMenu = useCallback(() => setIsMenuVisible(false), [])

  const isMarketplace = getEnv('NEXT_PUBLIC_IS_MARKETPLACE') === 'true'
  return (
    <ClickOutside active={isMenuVisible} onClick={hideMenu}>
      <div
        className={twMerge('flex justify-center lg:relative w-full', className)}
        {...props}
      >
        <SearchField
          value={searchTerm}
          // placeholder={!isSmallScreen ? undefined : 'searchNoun'}
          onChange={e => {
            setSearchTerm(e.target.value)
            onChange(e)
          }}
          onFocus={() => setIsMenuVisible(true)}
          onKeyDown={e => {
            onKeyDown(e, searchTerm)
            setIsMenuVisible(true)
          }}
          className="font-body min-w-full text-base text-black focus-within:outline-primary-light"
        />
        {isMenuVisible && (
          <div
            className={twMerge(
              'flex flex-col absolute w-[90vw] lg:w-full left-[5vw] top-15 lg:top-[calc(100%+0.2rem)] lg:left-0 bg-gray-0 h-auto max-h-[400px] min-h-[100px] overflow-auto rounded shadow-lg px-4',
              isLoading
                ? 'items-center justify-center py-15'
                : 'items-start justify-start py-3',
            )}
          >
            {isLoading && <Spinner className="size-8" />}
            {!searchTerm && (
              <div className="flex w-full gap-2 flex-col lg:flex-row">
                <RecentSearches />
                <ProductsResults
                  results={suggestedProducts}
                  searchTerm={searchTerm}
                  loading={featuredProductsLoading}
                  type="graphql"
                />
              </div>
            )}
            {!isLoading && searchTerm && (
              <div className="flex w-full gap-2 flex-col lg:flex-row">
                {searchSuggestions && !!searchSuggestions.length && (
                  <SuggestResults results={searchSuggestions} searchTerm={searchTerm} />
                )}
                {searchResults && !!searchResults.length && (
                  <ProductsResults results={searchResults} searchTerm={searchTerm} />
                )}
              </div>
            )}
            {!isLoading && searchResults && !!searchResults.length && searchTerm && (
              <SearchResults results={searchResults} searchTerm={searchTerm} />
            )}

            {!isLoading && !searchResults.length && (
              <div className="flex my-auto w-full justify-center items-center text-gray-700">
                <SearchIcon className="size-6" />
                <Text className="text-base text-gray-700 ml-4 my-4">
                  {searchTerm
                    ? t('products.noSearchResultsForTerm', { term: searchTerm })
                    : t('products.enterSearchTerm')}
                </Text>
              </div>
            )}

            {isMarketplace && !isLoading && searchTerm && !!searchResults.length && (
              <Shops results={searchResults} />
            )}
          </div>
        )}
      </div>
    </ClickOutside>
  )
}

Search.displayName = 'Search'

Search.propTypes = {
  children: PropTypes.node,
  initial: PropTypes.bool,
  className: PropTypes.string,
}

export default memo(Search)
